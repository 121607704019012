import { configureStore } from '@reduxjs/toolkit'
import systemTypeDefReducer from '../features/SystemTypeDef/systemTypeDefSlice'
import dashboardReducer from '../features/dashboard/dashboardSlice'
import userReducer from '../features/user/userSlice'
import residentReducer from '../features/Resident/residenceSlice'
const store = configureStore({
  reducer: {
    dashboard: dashboardReducer,
    user: userReducer,
    systemTypeDef: systemTypeDefReducer,
    resident: residentReducer,
  },
})

export default store
