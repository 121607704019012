import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requests } from 'src/services/requests'

export const getAllInvoiceType = createAsyncThunk('systemTypeDef/getAllInvoice', async (params) => {
  let res = await requests.getAllInvoiceType(params)
  return res.data
})

const initialState = {
  invoice: {
    allInvoiceTypes: [],
    loading: false,
    error: {},
    currentInvoice: {},
  },
}
const systemTypeDef = createSlice({
  name: 'typeDef',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllInvoiceType.pending, (state, action) => {
      state.invoice.loading = true
    })
    builder.addCase(getAllInvoiceType.fulfilled, (state, action) => {
      state.invoice.allInvoiceTypes = action.payload?.invoiceTypeDef
      state.invoice.loading = false
      state.invoice.error = null
    })
    builder.addCase(getAllInvoiceType.rejected, (state, action) => {
      state.invoice.allInvoiceTypes = []
      state.invoice.loading = false
      state.invoice.error = action.payload?.error
    })
  },
})

export default systemTypeDef.reducer
