import api from './api'
const authenticate = {
  login: (data) => api.post(`/users/authenticate`, data),
}
const residence = {
  getAllResidenceOwner: (data) => api.get(`/owner-resident-master/list`, data),
  createResidenceOwner: (data) => api.post(`/owner-resident-master/create`, data),
  updateResidenceOwner: (id, data) => api.post(`/owner-resident-master/update/${id}`, data),
  deleteSingleResidenceOwner: (id) => api.post(`/owner-resident-master/delete/${id}`),
  getAllResidenceRole: (data) => api.get('typedefination/residentroleDef/list', data),
  createResidenceRole: (data) => api.post('typedefination/residentroledef/create', data),
  updateResidenceRole: (id, data) => api.post(`typedefination/residentroleDef/update/${id}`, data),
  deleteResidenceRole: (id) => api.post(`typedefination/residentroleDef/delete/${id}`),
}
const typeDef = {
  getAllInvoiceType: () => api.get(`typedefination/invoicetypedef/list`),
}

export const requests = { ...residence, ...typeDef, ...authenticate }
