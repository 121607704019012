import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { requests } from 'src/services/requests'

export const getAllResidenceOwners = createAsyncThunk('resident-owners', async (params) => {
  console.log(params)
  let res = await requests.getAllResidenceOwner({ params })
  return res.data
})
export const getAllResidenceRoles = createAsyncThunk('residence-roles', async (params) => {
  let res = await requests.getAllResidenceRole({ params })
  return res.data
})
const getAllResidence = createAsyncThunk('residence/getAllResidence')
const initialState = {
  loading: false,
  error: {},
  allResidences: [],
  allResidentOwners: [],
  currentResidence: {},
  allResidenceRole: [],
}

const residentSlice = createSlice({
  name: 'resident',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllResidenceOwners.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getAllResidenceRoles.pending, (state, action) => {
      state.loading = true
    })
    builder.addCase(getAllResidenceOwners.fulfilled, (state, action) => {
      state.loading = false
      state.allResidentOwners = action.payload?.ownerResidentMaster
      state.error = null
    })
    builder.addCase(getAllResidenceRoles.fulfilled, (state, action) => {
      state.loading = false
      state.allResidenceRole = action.payload?.residentRoleDef
      state.error = null
    })
    builder.addCase(getAllResidenceRoles.rejected, (state, action) => {
      state.loading = false
      state.allResidenceRole = []
      state.error = null
    })
    builder.addCase(getAllResidenceOwners.rejected, (state, action) => {
      state.loading = false
      state.allResidentOwners = []
      state.error = action.payload?.error
    })
  },
})

export default residentSlice.reducer
